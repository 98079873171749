// Footer.js
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';
import LanguageSelector from './LanguageSelector';
import { useTranslation } from 'react-i18next';
import SocialMediaLinks from './SocialMediaLinks';
import { useUser } from '../store/user-context';

const WavedFooter = () => {
  const { t } = useTranslation();
  const { state } = useUser();
  const { isLoggedIn } = state;
  const isMobile = window.innerWidth < 768;


  return (
    <div className="footer-main">
      <div className="footer-wave">
        <svg viewBox="0 0 4930 306" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="none">
          <path d="M327.496 178.024C67 184 -132.273 95.4292 -208 42.1455V202H5364V42.1438C5312.61 66.1213 5176.17 128.071 5041.49 148.05C4873.13 178.024 4731.92 153.646 4606.4 106.586C4481.14 59.6268 4334.08 33.6528 4224.55 60.1264C3832.82 165.111 3559.76 -38.4748 3167 23C3052 41 2875.16 94.0971 2737.23 134.066C2599.3 166.035 2550.62 178.024 2428.91 178.024C2307.21 178.024 2254.83 164.554 2128.2 129.568C2027.29 98.0936 1556.7 -70.7574 1151.02 42.1455C745.345 150.048 637.384 170.916 327.496 178.024Z" fill="#0459b0"></path>
        </svg>

      </div>
      <footer className="footer-container">
        <div className="footer-subcontainer">
          <p className="footer-title">BibleLingua</p>
          {
            isMobile ? (
              <img height="113px" width="100px" className="footer-logo" src='/images/logo/logo-small.webp' alt="BibleLingua Logo" />
            ) : (
              <img height="113px" width="100px" className="footer-logo" src='/images/logo/logo.webp' alt="BibleLingua Logo" />
            )
          }
          <SocialMediaLinks />
        </div>
        <div className="footer-subcontainer">
          <p className="footer-subheader">{t('footer:information')}</p>
          <Link className="footer-link" to="/">{t('footer:about')}</Link>
          <Link className="footer-link" to="/guides?guideId=1">{t('footer:howToInstall')}</Link>
          <Link className="footer-link" to="/guides">{t('footer:guides')}</Link>
          <Link className="footer-link" to="/faq">{t('footer:faq')}</Link>
          
        </div>
        <div className="footer-subcontainer">
          <p className="footer-subheader">{t('footer:support')}</p>
          {
            isLoggedIn && (
              <Link className="footer-link" to="/help-request">{t('footer:contactSupport')}</Link>
            )
          }
          <Link className="footer-link" to="/contact-info">{t('footer:contactInformation')}</Link>
          <a className="footer-link footer-email" href="mailto:support@biblelimits.com">support@biblelimits.com</a>
          <span className='footer-link'>
            {t('footer:language')}
            <LanguageSelector customClass={'footer-language-selector'} />
          </span>
        </div>
        <div className="footer-subcontainer">
          <p className="footer-subheader">{t('footer:legalAgreements')}</p>
          <a className="footer-link" href="https://www.biblelimits.com/privacy-policy">{t('footer:privacyPolicy')}</a>
          <a className="footer-link" href="https://www.biblelimits.com/terms-of-use">{t('footer:termsAndConditions')}</a>
          <a className="footer-link" href="https://app.websitepolicies.com/policies/view/yxwugdb0">{t('footer:cookiePolicy')}</a><script src="https://cdn.websitepolicies.io/lib/embed/embed.min.js" defer></script>
        </div>
      </footer>
    </div>
  );
};

export default WavedFooter;
